<template>
  <div class="result-index">
    <app-title-content
      class="mb-4"
      :items="breadcrumbs"
      name="CheckStatusIndex"
    ></app-title-content>

    <div class="font-headblue fontsize-h4 d-flex align-items-center mb-4">
      <iconify icon="ant-design:file-filled"></iconify>
      <span class="weight-700 ml-1"
        >จำนวนผู้ทำแบบประเมินคุณภาพระบบประปาหมู่บ้าน แบ่งตามสำนักงานน้ำภาค</span
      >
    </div>
    <b-overlay :show="isLoadStatus">
      <div class="d-flex row" style="padding-left: 15px; padding-right: 15px">
        <div
          class="col-md-3 col-lg col-auto mb-2"
          style="padding-left: 5px; padding-right: 5px"
          v-for="data in listCard"
          :key="data.departmentOrder"
        >
          <b-card class="card-custom">
            <span class="weight-700">{{ data.shortnessDepartment }}</span
            ><br />
            <span>ประเมินจำนวน</span><br />
            <span
              v-if="data.amount !== null"
              class="weight-700 font-headblue fontsize-h2"
            >
              {{ data.qualitySurveyCount }}
            </span>
            <span v-else class="weight-700 font-headblue fontsize-h2">-</span
            ><br />
            <span class="font-disable">รายการ</span>
          </b-card>
        </div>
      </div>
    </b-overlay>
    <div class="font-headblue fontsize-h4 d-flex align-items-center my-4">
      <iconify icon="ant-design:pie-chart-filled"></iconify>
      <span class="weight-700 ml-1"
        >รายการข้อมูลการทำแบบประเมินคุณภาพระบบประปาหมู่บ้าน</span
      >
    </div>
    <b-overlay :show="isLoadList">
      <div class="d-flex">
        <div class="pr-2" style="width: 27%">
          <select2
            :options="PWATypeList"
            v-model="PWATypeSelect"
            class="select-custom"
            placeholder="ประเภทแหล่งน้ำ"
            :settings="{
              language: {
                noResults: function () {
                  return 'ไม่พบข้อมูล';
                },
              },
              allowClear: 'true',
            }"
          ></select2>
        </div>
        <div class="px-2" style="width: 22%">
          <select2
            :options="departmentList"
            v-model="departmentSelect"
            class="select-custom"
            placeholder="สทภ."
            :settings="{
              language: {
                noResults: function () {
                  return 'ไม่พบข้อมูล';
                },
              },
              allowClear: 'true',
            }"
          ></select2>
        </div>
        <div class="px-2" style="width: 17%">
          <select2
            :options="provinceList"
            v-model="proviceSelect"
            class="select-custom"
            placeholder="จังหวัด"
            :settings="{
              language: {
                noResults: function () {
                  return 'ไม่พบข้อมูล';
                },
              },
              allowClear: 'true',
            }"
            @change="getAmphur($event)"
          ></select2>
        </div>
        <div class="px-2" style="width: 17%">
          <select2
            :options="amphurList"
            v-model="amphurSelect"
            class="select-custom"
            placeholder="อำเภอ"
            :settings="{
              language: {
                noResults: function () {
                  return 'ไม่พบข้อมูล';
                },
              },
              allowClear: 'true',
            }"
            @change="getTambol($event)"
          ></select2>
        </div>
        <div class="px-2" style="width: 17%">
          <select2
            :options="tambolList"
            v-model="tambolSelect"
            class="select-custom"
            placeholder="ตำบล"
            :settings="{
              language: {
                noResults: function () {
                  return 'ไม่พบข้อมูล';
                },
              },
              allowClear: 'true',
            }"
          ></select2>
        </div>

        <div class="pl-2">
          <button class="btn button-custom" @click="search">
            <iconify icon="akar-icons:search" class="fontsize-h4"></iconify>
          </button>
        </div>
      </div>
      <div class="my-4 box-shadow py-4 px-5">
        <div class="d-flex justify-content-between mb-4">
          <span class="weight-700 fontsize-h5 align-self-end">
            แบบประเมินคุณภาพระบบประปาหมู่บ้าน จำนวน
            {{ total | toCommas }} รายการ
            <b-badge class="badge-custom">{{ badgePWAType }}</b-badge>
            <b-badge class="badge-custom">{{ badgeDepartment }}</b-badge>
            <b-badge class="badge-custom">{{ badgeProvice }}</b-badge>
            <b-badge class="badge-custom">{{ badgeAmphur }}</b-badge>
            <b-badge class="badge-custom">{{ badgeTambol }}</b-badge>
            <!-- <b-badge class="badge-custom">Info</b-badge> -->
          </span>
          <div class="d-flex flex-column">
            <span class="font-reddanger mb-2"
              >เนื่องจากข้อมูลที่ต้องแสดงมีปริมาณมาก การดาวน์โหลดจะใช้เวลา 5
              นาทีขึ้นไป</span
            >
            <div class="align-self-end">
              <b-overlay :show="isLoadBtn">
                <button
                  class="
                    btn btn-download
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                  @click="downloadExcel"
                >
                  <iconify icon="uiw:file-excel" class="mr-2" />
                  ดาวน์โหลด
                </button>
              </b-overlay>
            </div>
          </div>
        </div>
        <b-table
          id="my-table"
          hover
          borderless
          :no-border-collapse="true"
          :items="items"
          :fields="fields"
          class="table-custom"
          show-empty
          :busy.sync="isBusy"
        >
          <template v-slot:empty="">
            <div class="text-center">ไม่พบข้อมูล</div>
          </template>
          <div slot="table-busy" class="text-center font-blueinfo my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="pl-2">กำลังโหลด...</strong>
          </div>
          <template v-slot:head()="data">
            <div
              :class="
                data.field.align === 'center' ? 'text-center' : 'text-left'
              "
            >
              {{ data.label }}
            </div>
          </template>
          <template v-slot:cell()="data">
            <div
              :class="
                data.field.align === 'center' ? 'text-center' : 'text-left'
              "
            >
              {{ data.value }}
            </div>
          </template>
          <!-- Row running number -->
          <template v-slot:cell(index)="data">
            <div class="text-center">
              {{ (currentPage - 1) * perPage + (data.index + 1) }}
            </div>
          </template>
          <template #cell(evaluation)="row">
            <iconify
              :icon="getIcon(row.item.assessmentResult.statusTextSymbol)"
              class="fontsize-h5"
              :class="getColorIcon(row.item.assessmentResult.statusTextSymbol)"
            />
            {{ row.item.assessmentResult.statusText }}
          </template>
          <template #cell(detail)="row">
            <div class="d-flex">
              <font-awesome-icon
                icon="file-alt"
                class="fontsize-h2 font-systemblue pr-2"
              />
              <a
                class="font-systemblue cursor-pointer"
                @click="onViewResult(row)"
                >เรียกดู</a
              >
            </div>
          </template>
          <template #cell(delete)="row">
            <div class="text-center">
              <button
                type="button"
                class="btn btn-trash"
                @click="deleteRow(row.item)"
              >
                <font-awesome-icon
                  :icon="['fas', 'trash-alt']"
                  class="color-icon-trash fa-lg"
                />
              </button>
            </div>
          </template>
        </b-table>
        <b-pagination
          class="pagination-custom mt-3"
          v-model="currentPage"
          @change="changePage($event)"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
        ></b-pagination>
      </div>
    </b-overlay>
    <div class="d-flex justify-content-center">
      <button
        type="submit"
        class="btn btn-outline-submit"
        @click="gotoMenuPage()"
      >
        <iconify icon="fa-solid:home" class="fontsize-h4 mr-2" />
        กลับไปยังหน้ารายการ
      </button>
    </div>
  </div>
</template>

<script>
import {
  FilterService,
  StatusService,
  AssessmentService,
} from "@/services/main.service.js";
export default {
  name: "result-index",
  data() {
    const breadcrumbs = [
      {
        to: "CheckStatusIndex",
        text: "ตรวจสอบสถานะการตอบแบบประเมิน",
      },
    ];
    return {
      apiURL: null,
      isLoadStatus: false,
      isLoadList: false,
      isLoadBtn: false,
      perPage: 50,
      currentPage: 1,
      isBusy: false,
      breadcrumbs: breadcrumbs,
      listCard: [],
      options: [],
      PWATypeList: [],
      PWATypeSelect: null,
      departmentList: [],
      departmentSelect: null,
      provinceList: [],
      proviceSelect: null,
      amphurSelect: null,
      amphurList: [],
      tambolList: [],
      tambolSelect: null,

      badgePWAType: null,
      badgeDepartment: null,
      badgeProvice: null,
      badgeAmphur: null,
      badgeTambol: null,
      total: 0,
      items: [],
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          align: "center",
        },
        {
          key: "villageName",
          label: "หมู่บ้าน",
        },
        {
          key: "villageNameNumber",
          label: "หมู่",
        },
        {
          key: "tambolName",
          label: "ตำบล",
        },
        {
          key: "amphorName",
          label: "อำเภอ",
        },
        {
          key: "provinceName",
          label: "จังหวัด",
        },
        {
          key: "systemPlumbingName",
          label: "ชื่อระบบประปา",
        },
        {
          key: "pwaType",
          label: "ประเภทแหล่งน้ำ",
        },
        {
          key: "evaluation",
          label: "ผลการประเมิน",
        },
        {
          key: "detail",
          label: "ดูสรุปผล",
        },
        { key: "delete", label: "ลบ", align: "center" },
        {
          key: "modifyDate",
          label: "วันที่อัพเดตข้อมูล",
        },
      ],
    };
  },
  created() {
    this.apiURL = process.env.VUE_APP_BASE_API_DOWNLOAD;

    this.getPWAType();
    this.getDepartment();
    this.getProvince();
    this.getStatus();
    this.getListQualitySurvey();
  },
  methods: {
    gotoMenuPage() {
      this.$router.push({ name: "MenuPage" });
    },
    getIcon(item) {
      if (item === "A") return "bi:emoji-heart-eyes-fill";
      else if (item === "B") return "bi:emoji-smile-fill";
      else if (item === "C") return "bi:emoji-wink-fill";
      else if (item === "D") return "bi:emoji-neutral-fill";
      else if (item === "F") return "bi:emoji-frown-fill";
    },
    getColorIcon(item) {
      if (item === "A") return "font-graph-green";
      else if (item === "B") return "font-graph-softgreen";
      else if (item === "C") return "font-graph-yellow";
      else if (item === "D") return "font-graph-orange";
      else if (item === "F") return "font-graph-rad";
    },
    async getProvince() {
      await FilterService.getListProvince()
        .then((res) => {
          this.provinceList = res?.data.map((m) => {
            return { ...m, text: m.name };
          });
        })
        .catch((err) => {
          this.provinceList = [];
        });
    },
    async getAmphur(provinceId = null, id = null) {
      this.amphurList = [];
      this.amphurSelect = null;
      this.tambolList = [];
      this.tambolSelect = null;
      await FilterService.getListAmphur(provinceId)
        .then((res) => {
          this.amphurList = res?.data.map((m) => {
            return { ...m, text: m.name };
          });
        })
        .catch((err) => {
          this.amphurList = [];
        });
    },
    async getTambol(amphurId = null, id = null) {
      this.tambolSelect = null;
      this.tambolList = [];
      await FilterService.getListTambol(amphurId)
        .then((res) => {
          this.tambolList = res?.data.map((m) => {
            return { ...m, text: m.name };
          });
        })
        .catch((err) => {
          this.tambolList = [];
        });
    },
    async getDepartment() {
      await FilterService.getListDepartment(null, 1)
        .then((res) => {
          this.departmentList = res?.data.map((m) => {
            return { ...m, text: m.name };
          });
        })
        .catch((err) => {
          this.departmentList = [];
        });
    },
    async getPWAType() {
      await FilterService.getListPWAType()
        .then((res) => {
          this.PWATypeList = res?.data.map((m) => {
            return { ...m, text: m.name };
          });
        })
        .catch((err) => {
          this.PWATypeList = [];
        });
    },
    deleteRow(detail) {
      this.alertDeleteForm().then(async (result) => {
        if (result) {
          //call service delete
          await AssessmentService.postAssessmentFormDelete(
            detail.qualitySurveyID
          )
            .then((res) => {
              if (res.actionId !== 0) {
                this.alertSuccess("ลบรายการสำเร็จ");
                //refresh list
                this.getListQualitySurvey();
              }
            })
            .catch((err) => {
              this.alertFail("ลบรายการไม่สำเร็จ");
            });
        }
      });
    },
    search() {
      this.badgePWAType = this.PWATypeList.filter((f) => {
        return f.id === parseInt(this.PWATypeSelect);
      })[0]?.text;
      this.badgeDepartment = this.departmentList.filter((f) => {
        return f.id === parseInt(this.departmentSelect);
      })[0]?.text;
      this.badgeProvice = this.provinceList.filter((f) => {
        return f.id === parseInt(this.proviceSelect);
      })[0]?.text;
      this.badgeAmphur = this.amphurList.filter((f) => {
        return f.id === parseInt(this.amphurSelect);
      })[0]?.text;
      this.badgeTambol = this.tambolList.filter((f) => {
        return f.id === parseInt(this.tambolSelect);
      })[0]?.text;
      this.currentPage = 1;
      this.getListQualitySurvey();
    },
    async getStatus() {
      this.isLoadStatus = true;
      await StatusService.getStatus()
        .then((res) => {
          this.listCard = res?.data.map((m) => {
            return { ...m };
          });
          this.isLoadStatus = false;
        })
        .catch((err) => {
          this.listCard = [];
          this.isLoadStatus = false;
        });
    },
    async getListQualitySurvey() {
      this.isBusy = true;
      const param = {
        NumPage: this.currentPage,
        NumRow: this.perPage,
        TypeWaterID: this.PWATypeSelect,
        DepID: this.departmentSelect,
        ProvinceID: this.proviceSelect,
        AmphorID: this.amphurSelect,
        TambolID: this.tambolSelect,
      };
      await AssessmentService.getListQualitySurvey(param)
        .then((res) => {
          this.total = res.total;
          this.items = res.data.map((m) => {
            return {
              ...m,
            };
          });
        })
        .catch((err) => {
          this.items = [];
          this.total = 0;
          this.currentPage = 1;
        })
        .finally((f) => {
          this.isBusy = false;
        });
    },
    async changePage(event) {
      this.currentPage = event;

      await this.getListQualitySurvey();
    },
    onViewResult(data) {
      const param = {
        surveyID: data.item.qualitySurveyID,
      };
      let routeData = this.$router.resolve({
        name: "StatusSummary",
        query: {
          ...param,
        },
      });
      window.open(routeData.href, "_blank");
    },
    async downloadExcel() {
      this.isLoadBtn = true;

      const param = {
        TypeWaterID: this.PWATypeSelect,
        DepID: this.departmentSelect,
        ProvinceID: this.proviceSelect,
        AmphorID: this.amphurSelect,
        TambolID: this.tambolSelect,
      };

      await AssessmentService.getExportExcel(param)
        .then((res) => {
          if (res) {
            const path = res.path;

            if (path && this.apiURL) {
              let file = `${this.apiURL}/${path}`;
              try {
                window.open(file, "_blank");
                this.isLoadBtn = false;
              } catch (err) {
                this.alertDownloadFail();
                this.isLoadBtn = false;
              }
            } else {
              this.alertDownloadFail();
              this.isLoadBtn = false;
            }
          }
        })
        .catch((err) => {
          this.alertDownloadFail();
          this.isLoadBtn = false;
        });
    },
    async downloadExcel2() {
      const param = {
        TypeWaterID: this.PWATypeSelect,
        DepID: this.departmentSelect,
        ProvinceID: this.proviceSelect,
        AmphorID: this.amphurSelect,
        TambolID: this.tambolSelect,
      };

      const w = window.open("/status/download", "_blank");
      await AssessmentService.getExportExcel(param)
        .then((res) => {
          if (res) {
            const path = res.path;

            if (path && this.apiURL) {
              let file = `${this.apiURL}/${path}`;
              try {
                console.log(file);
                window.open(file, "_blank");
              } catch (err) {
                this.alertDownloadFail();
              }
            } else {
              this.alertDownloadFail();
            }
          }
        })
        .catch((err) => {
          this.alertDownloadFail();
        })
        .finally(() => {
          setTimeout(function () {
            w.close();
          }, 1000);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-custom .card-body {
  padding: 12px;
}
.card-custom {
  border: 0px !important;
}
.button-custom {
  border: 1px solid $greenmint;
  background: $greenmint;
  color: $white;
  &:hover {
    opacity: 0.9;
    color: $white;
  }
}
</style>
